(function ($) {
    // Handler for .ready() called.
    //alert('Ready');

    $(window).on('load', function () {
        if ($(".animatecss").length > 0) {
            var $collection = $('.animatecss');
            $collection.each(function () {
                new Waypoint.Inview({
                    element: this,
                    enter: function (direction) {
                        $(this.element).removeClass($(this.element).data("animation-out") + " xhidden").addClass($(this.element).data('animation'));
                    },
                    // exited: function (direction) {
                    //     if (!isMobile()) {
                    //         if ($(this.element).hasClass('anim2')) {
                    //             $(this.element).removeClass($(this.element).data("anim2"));
                    //         }
                    //         $(this.element).removeClass($(this.element).data("animation")).addClass($(this.element).data("animation-out"));
                    //     }
                    // },
                })
            });
        }
    });

    $.fn.unveil = function (threshold, callback) {

        var $w = $(window),
            th = threshold || 0,
            retina = window.devicePixelRatio > 1,
            attrib = retina ? "data-src-retina" : "data-src",
            images = this,
            loaded;

        this.one("unveil", function () {
            var source = this.getAttribute(attrib);
            source = source || this.getAttribute("data-src");
            if (source) {
                this.setAttribute("src", source);
                if (typeof callback === "function") callback.call(this);
            }
        });

        function unveil() {
            var inview = images.filter(function () {
                var $e = $(this);
                if ($e.is(":hidden")) return;

                var wt = $w.scrollTop(),
                    wb = wt + $w.height(),
                    et = $e.offset().top,
                    eb = et + $e.height();

                return eb >= wt - th && et <= wb + th;
            });

            loaded = inview.trigger("unveil");
            images = images.not(loaded);
        }

        $w.on("scroll.unveil resize.unveil lookup.unveil", unveil);

        unveil();

        return this;

    };

    var productList = {
        'kind_always': { title: 'Be Kind', 'desc': '<p>Kindness is like sugar; it makes life sweeter! Gummy bear beads, peace signs, alphabet letters that spell “KIND ALWAYS” — this donut-shaped box of goodness holds the ingredients you need to make 3 beaded accessories & stay full of 100% happiness.</p><p><strong>SET INCLUDES:</strong> 1 Collectible Donut-Shaped Keychain, 1 Collectible Peace Sign Bead, 1 Smiley Face Bead, 1 Yellow Carabiner Clip, 2 Round Rainbow Beads, 5 Assorted Colorful Beads, 1 Smiley Face Charm, 1 Round Rubber Bead, 3 Skeins Elastic Cording, 2 Gummy Bear Charms, 10 Alphabet Beads (“KIND ALWAYS”), 20 Colorful Round Beads, Easy-to-Follow Instructions</p>', 'gallery': ['images/inline/kind_always_1.png', 'images/inline/kind_always_2.png'], 'stores': [{ thumb: 'images/inline/amazon.png', link: 'https://www.amazon.com/dp/B09LCLW155' }] },
        'sunshinevibes': { title: 'Sunshine Vibes', 'desc': '<p>Have you had your daily dose of sunshine?! Bring on the flower power, peace signs, hearts, and golden “SUNSHINE VIBES” with the sweetest little box of happiness. Design 3 beaded accessories that inspire you to shine as bright as the sun!</p><p><strong>SET INCLUDES:</strong> 1 Collectible Donut-Shaped Container, 2 Peace Sign Beads, 20 Translucent Colorful Pastel Round Beads, 1 Purple Carabiner Clip, 2 Gold Heart Beads, 1 Collectible Smiley Face Charm, 1 Round Rubber Bead, 1 Rubber Flower Bead, 3 Skeins Elastic Cording, 2 Smiley Face, 13 Alphabet Beads (“SUNSHINE VIBES”), 2 Heart Beads, 6 Translucent 3D Heart Beads, Easy-to-Follow Instructions</p>', 'gallery': ['images/inline/sunshinevibes_1.png', 'images/inline/sunshinevibes_2.png'], 'stores': [{ thumb: 'images/inline/amazon.png', link: '' }] },
        'shinebright': { title: 'Shine Bright', 'desc': '<p>You light up the world just by being in it — so make & create positivity jewelry that empowers you to “SHINE BRIGHT” like a star! From glowing hearts to starry-eyed smiles, this one little donut is filled with endless good vibes.</p><p><strong>SET INCLUDES:</strong> 1 Collectible Donut-Shaped Keychain, 11 Alphabet Beads (“SHINE BRIGHT”), 1 Bright Orange Carabiner Clip, 3 Star-Shaped Beads, 2 Metallic Gold Star Beads, 1 Collectible Smiley Face Charm, 1 Round Rubber Bead, 2 Rubber Star-Shaped Beads, 3 Skeins Elastic, 6 Colorful Cylinder Beads, 4 Clear Cylinder Beads, 4 Heart Beads, 5 Translucent Star Beads, 3 Smiley Face Beads, Easy-to-Follow Instructions</p>', 'gallery': ['images/inline/shinebright_1.png', 'images/inline/shinebright_2.png'], 'stores': [{ thumb: 'images/inline/amazon.png', link: 'https://www.amazon.com/dp/B09LCD9R7H' }] },
        'smile': { title: 'Happy Day', 'desc': '<p>Wear smiles like your favorite accessories! This collectible Donut Worries DIY jewelry set comes with all the ingredients you need to make every day a happy day: pretty pearls, feel-good flowers, fun-filled friends, smiles, and more!</p><p><strong>SET INCLUDES:</strong> Set Includes: 1 Collectible Donut-Shaped Container, 5 Alphabet Beads (“SMILE”), 13 White Pearl Beads, 1 Smiley Face Bead, 1 Pink Carabiner Clip, 1 Collectible Smiley Face Charm, 2 Rubber Flower Beads, 5 Transparent Smiley Face Beads, 3 Skeins Elastic Cording, 1 Yellow Smiley Face Bead, Easy-to-Follow Instructions</p>', 'gallery': ['images/inline/smile_1.png', 'images/inline/smile_2.png'], 'stores': [{ thumb: 'images/inline/amazon.png', link: '' }] }
    };
    var audioElement = false;

    function generateFloatingImage(obj, count) {

        total = $('.rising-img.cloned').length;
        prevBottom = 0;
        for (i = total; i < total + count; i++) {
            clone = obj.clone();
            clone.attr("data-id", i);
            clone.removeClass("main").addClass('cloned');

            if (i >= count / 2) {
                clone.addClass('delayed');
            }

            modl = i % 2;
            if (modl == 0) {
                clone.addClass('smiley');
            } else {
                clone.addClass('donut');
            }

            if ((i % 4 === 2) || (i % 4 === 3)) {
                clone.addClass('sm');
            }

            // var b = Math.random() * 800 * (modl + 1);
            var b = Math.random() * $(window).height() * (modl + 1);
            if ((prevBottom !== 0) && (Math.abs(b - prevBottom) < 100)) {
                b += 100;
                prevBottom = b;
            }
            var l = Math.random() * $(window).width();
            clone.css({ "left": l, "bottom": - b });
            //clone.css({"left": Math.random() * $(window).width(), "bottom": - b});
            clone.appendTo($('#main-content'));
        }
    }

    $(document).on('click', function (e) {
        if ($(e.target).hasClass('modal')) {
            closeModal();
        }
    });

    $('.close-modal').on('click', function (e) {
        e.preventDefault();
        closeModal();
    });


    $("img.lazy").unveil(300, function () {
        //$(this).load(function() {
        $(this).removeClass('lazy');
        //});
    });

    $('a[href="#scroll-dwn"]').on('click', function (e) {
        e.preventDefault();
        targtSection = $('#' + $(this).data('section'));
        if (targtSection.length > 0) {
            $('html, body').animate({
                scrollTop: targtSection.offset().top
            }, 600);
        }
    })

    //Section Scrolling
    $('.section-scroll').on('click', function (e) {
        e.preventDefault();
        $('body').removeClass('landing').addClass('off');
        targetSection = $('#section-' + $(this).data('section'));
        targetSection.removeClass('off');
        if ($(this).data('section') == 'kids') {
            $('body').addClass('kids');
        } else {
            $('body').removeClass('kids');
        }

        if ($(this).hasClass('section-link')) {
            $('.page-section').not(targetSection).fadeOut(400, function () {
                targetSection.fadeIn();
            });
            // $('html').animate({
            //     scrollTop: 0
            // }, 600, "linear", function () {
            //     $('.page-section').not(targetSection).addClass('off');
            //     if (!$('#section-kids').hasClass('off')) {
            //         $('body').addClass('kids');
            //     }
            // });
            if ($('#screen-2').hasClass('active')) {
                _resetGame();
            }
        } else {
            $('html').animate({
                scrollTop: targetSection.offset().top
            }, 600, "linear", function () {
                $('.page-section').not(targetSection).addClass('off').css('display', 'none');
                // if (!$('#section-kids').hasClass('off')) {
                //     $('body').addClass('kids');
                // }
            });
        }

    });

    $('body').on('animationend webkitAnimationEnd oAnimationEnd', '#game-screen .tire', function () {
        //$('#game-screen .moving-truck').parents('.screen').addClass('stop');
        setTimeout(function () {
            $("#game-screen .game-action").fadeTo(600, 1, function () {
                // Animation complete.
            });
        }, 500);

    });

    $('body').on('animationend webkitAnimationEnd oAnimationEnd', '.progress-bar .bar', function () {
        $('#game-screen .game-action').addClass('done');
        $('#game-screen').addClass('results');
        $('.screen.game-results').addClass('active');
    });
    var selectedIngr = [];
    $('body').on('click', '.reset-game', function (e) {
        e.preventDefault();
        _resetGame();
        $('html, body').animate({
            scrollTop: $('#screen-1').offset().top - 100
        }, 600);
        selectedIngr = [];
    });

    function _resetGame() {
        $('#game-screen .screen').removeClass('active');
        $('#screen-1').addClass('active');
        $('#screen-2').removeClass('stop');
        $('#game-screen .game-action .donuts').removeClass('hide-items');
        $('#screen-2 .game-action').removeClass('start-mixing done');
        $('#screen-2 .game-action .tip-box').fadeIn();
        $('#game-screen').removeClass('start results');
        $('#board-ingredients li:not(.title)').remove();
        $('.levers span').removeClass('on').addClass('off');
        $('#mixing-bowl .toppings span').remove();
        $('#game-screen .game-action > img').fadeTo('fast', 1);
        $('#game-screen .game-action').fadeTo('fast', 0);
        $('.ui-draggable-disabled.on').removeClass('on');
        $('#game-screen .bottles span, #game-screen .cones span, #game-screen .donuts span').draggable('enable');
    }

    $('body').on('webkitTransitionEnd oTransitionEnd msTransitionEnd transitionend', '#game-screen.start .screen-wrapper', function () {
        $('#game-screen #screen-1').removeClass('active');
    });

    $('#screen-1 .ingr-icon.pop-icon').on('click', function (e) {
        item = $('<span style="position: absolute; top: ' + $(this).position().top + 'px; left: ' + $(this).position().left + 'px;" class="animate__animated animate__fadeOutUpLong icon inter ' + $(this).data('type') + '"/>');
        playSound('6');
        $(this).parent().append(item);
    });

    $('body').on('click', '.screen .start-game', function (e) {
        e.preventDefault();
        var that = this;
        $('#screen-2').addClass('active');
        // $('#game-screen').addClass('start');
        $('html, body').animate({
            scrollTop: $('#screen-2').offset().top - 100
        }, 600, function () {
            $(that).parents('#game-screen').addClass('start');
        });

        //Wait for the slide transition to end before disactivating them
        // $('#game-screen .screen-wrapperD').slick('slickGoTo', 1);
    });

    /* Game interaction */
    //Handling dragging
    $('*[draggable!=true]', '.slick-track').unbind('dragstart');
    $('#game-screen .bottles span, #game-screen .cones span').draggable({
        helper: 'clone',
        revertDuration: 100,
        revert: 'invalid',
        containment: '.game-action',
        start: function (event, ui) {
            $(event.target).parent().addClass('z10');
            $('#game-screen .tip-box').fadeOut();
        },
        stop: function (event, ui) {
            // if(!$(event.target).parents('.drag-track').hasClass('item-dropped')){
            //     $(event.target).removeAttr('style');
            // }
            $(event.target).parent().removeClass('z10');
            //$('#game-screen .tip-box').fadeIn();
        },
        drag: function (event, ui) {
            ui.helper.addClass('dragged-elm');
        },
    });

    $('.flip-card').on('click', function () {
        $(this).toggleClass('front back');
    });

    //Slick
    // $('#game-screen .screen-wrapperD').slick({
    //     infinite: false,
    //     rows: 0,
    //     prevArrow: '',
    //     nextArrow: '',
    //     // click: true,
    //     swipe: false,
    //     // touchMove: false,
    //     accessibility: true,
    //     slidesToShow: 1,
    //     dots: false,
    //     adaptiveHeight: false,
    // });


    // $("#game-screen .bottles span, #game-screen .cones span").on("draggable mouseenter mousedown",function(e){
    //     //e.stopPropagation();
    // });

    $('#game-screen .bottles span, #game-screen .cones span, #game-screen .donuts span').draggable({
        helper: 'clone',
        revertDuration: 100,
        revert: 'invalid',
        containment: '.game-action',
        start: function (event, ui) {
            $(event.target).parent().addClass('z10');
            $('#game-screen .tip-box').fadeOut();
        },
        stop: function (event, ui) {
            // if(!$(event.target).parents('.drag-track').hasClass('item-dropped')){
            //     $(event.target).removeAttr('style');
            // }
            $(event.target).parent().removeClass('z10');
            //$('#game-screen .tip-box').fadeIn();
        },
        drag: function (event, ui) {
            ui.helper.addClass('dragged-elm');
        },
    });

    $('#mixing-bowl .toppings').droppable({
        accept: '.mix-item',
        drop: function (event, ui) {
            playSound('2');
            _addselection($('#mixing-bowl .toppings'), $(ui.draggable).data('slug'), $(ui.draggable).parent().data('type'));
            $(ui.draggable).draggable('disable');
            $(ui.draggable).addClass('on');
            if ($(ui.draggable).hasClass('donut-item')) {
                $(ui.draggable).parents('.donuts').addClass('hide-items');
            }
        },
    });

    $('body').on('click', '.levers span.off', function () {
        $(this).toggleClass('off on');
        playSound('1');
        _addselection($('#mixing-bowl .toppings'), $(this).data('slug'), 'lever');
    });

    $('.start-mixing').on('click', function (e) {
        e.preventDefault();
        if (selectedIngr.length == 0) {
            alert('Please select some ingredients.');
            return false;
        }

        // $('#game-screen .game-action > img').fadeTo(200, 0, function () {
        //     $('#game-screen .game-action').addClass('start-mixing');
        //     $('#mixing-bowl .toppings > img').remove();
        //     _addIconstoBowl($('#mixing-bowl .toppings'));
        // });

        $.when($('#game-screen .game-action > img').fadeTo(200, 0))
            .done(function () {
                $('#game-screen .game-action').addClass('start-mixing');
                $('#mixing-bowl .toppings > img').remove();
                _addIconstoBowl($('#mixing-bowl .toppings')); //Using the $when construct + done for callback to run once
            });

    });
    function _addIconstoBowl(obj) {
        var numItems;
        var totalCount = 0;
        $divWidth = obj.width();
        if (selectedIngr.length <= 6) {
            numItems = 6;
        } else {
            numItems = 3;
        }

        $.each(selectedIngr, function (idx, value) {
            total = numItems;
            if (value == 'sparkle') {
                total = numItems * 2;
            }

            for (var i = 0; i < total; i++) {
                var item = $('<span style="--animation-order:' + (totalCount + 1) + ' ;" class="icon ' + value + '"/>');
                if ((totalCount % 2) === 0) {
                    item.addClass('even');
                } else {
                    item.addClass('odd');
                }
                var rNum = Math.floor((Math.random() * 1000));
                obj.append(item);

                item.css({
                    //bottom: btmStart + 'px', //Math.random() * (obj.height() - item.height()) + 'px'
                    bottom: '-6%', //'-12%', //30px/243px height
                    'left': ((Math.floor(Math.random() * Number($divWidth - 50)) / $divWidth) * 100) + '%',
                    // 'transform': 'rotate('+ rNum +'deg)'
                })
                totalCount += 1;
            }
        });

        if ($('.donuts .donut-item.on').length == 0) {
            $("#result-images img.front").attr("src", $('#result-images img.front').data('image'));
            $("#result-images img.back").attr("src", $('#result-images img.back').data('image'));
        } else {
            $("#result-images img.front").attr("src", 'images/inline/' + $('.donuts .donut-item.on').data("slug") + '_1.png');
            $("#result-images img.back").attr("src", 'images/inline/' + $('.donuts .donut-item.on').data("slug") + '_2.png');
        }

        var _innerTotal = 0; //To make sure adds up to 100%
        $.each(_randomFill($('#res-ingredients li').length, 0.2, 0.5), function (index, value) {
            if (index == ($('#res-ingredients li').length - 1)) { //This is the last item
                $('#res-ingredients li:eq(' + index + ') .num').text((100 - _innerTotal) + '%');
            } else {
                _innerTotal += value;
                $('#res-ingredients li:eq(' + index + ') .num').text(value + '%');
            }
        });
    }

    function _random(min, max) {
        return min + (Math.random() * (max - min));
    }

    function _randomFill(amount, min, max) {
        arr = [];
        total = 0;
        for (var i = 0; i < amount; i++) {
            _citem = Math.round((100 * _random(min, max)));
            arr.push(_citem);
            total += _citem;
        }

        for (var j = 0; j < amount; j++) {
            arr[j] = Math.round(100 * (arr[j] / total));
        }
        return arr;
    }

    function playSound(f_id) {
        f_id = f_id || 1;
        //var fileList = ['sounds/sound1.mp3', 'sounds/sound2.mp3', 'sounds/sound3.mp3', 'sounds/sound4.mp3', 'sounds/sound5.mp3', 'sounds/sound6.mp3'];

        if (!audioElement) {
            audioElement = document.createElement('audio');
            audioElement.setAttribute('src', 'sounds/sound' + f_id + '.mp3');
        } else {
            audioElement.setAttribute('src', 'sounds/sound' + f_id + '.mp3');
        }
        //audioElement.load();
        audioElement.play();
        audioElement.addEventListener('ended', function () {
            audioElement.currentTime = 0;
        }, false);
    }

    function _addselection(obj, cssclass, type) {
        var numItems = 2;
        var lookup = {
            'love': 'Heap', 'joy': 'Zest', 'compassion': 'Cup', 'sparkle': 'Sprinkle', 'sharing': 'Spoonful', 'gratitude': 'Touch',
            'generosity': 'Pinch',
            'sunshine': '1 Splash of Color',
            'kindness': 'Bunch of Strength',
            'love-cone': '24 Carat Heart of Gold',
            'happiness': 'Smidgen of Smiles',
            'shine': 'Shake of Luck',
            'shimmer': 'Shake of Shine',
            'kind_always': 'Loads of Kindness',
            'smile': 'Endless Smiles',
            'sunshinevibes': 'Scoop of Sunshine',
            'shinebright': 'The Secret Ingredient',
        }
        if (cssclass == 'sparkle') {
            numItems = 4;
        }

        if (type == 'lever') {
            $('#board-ingredients').append('<li class="' + cssclass + '">' + ((cssclass == 'frosting-b') ? '1 Squirt of Sweetness' : '1 Dash of Sass') + '</li>');
        } else if ((type == 'cone') || (type == 'donut')) {
            if (!isEmpty(lookup[cssclass])) {
                $('#board-ingredients').append('<li class="' + cssclass + '">' + lookup[cssclass] + '</li>');
            }
        } else {
            $('#board-ingredients').append('<li class="' + cssclass + '">1 ' + (isEmpty(lookup[cssclass]) ? 'dash' : lookup[cssclass]) + ' of ' + cssclass.split('_')[0] + '</li>');
        }
        obj.append('<img src="images/inline/' + cssclass + '-bowl.png" alt="" />');
        selectedIngr.push(cssclass);
        // for (var i = 0; i < numItems; i++) {
        //     var item = $('<span class="icon ' + cssclass + '"/>');
        //     var rNum =  Math.floor( (Math.random() * 1000));
        //     obj.append(item);

        //     item.css({
        //         top: Math.random() * (obj.height() - item.height()) + 'px',
        //         left: Math.random() * (obj.width() - item.width()) + 'px',
        //         'transform': 'rotate('+ rNum +'deg)'
        //     })
        // }
    }

    function isEmpty(mixed_var) {
        mixed_var = jQuery.trim(mixed_var);
        var key;
        if (mixed_var === '' || mixed_var === 0 || mixed_var === '0' || mixed_var === null || mixed_var === false || typeof mixed_var === 'undefined') {
            return true;
        }

        if (typeof mixed_var === 'object') {
            for (key in mixed_var) {
                return false;
            }
            return true;
        }
        return false;
    }


    function closeModal() {
        $('#dw-modal').removeClass('active');
        $('body').removeClass('ov-hidden');
        $('#dw-modal .copy').mCustomScrollbar('destroy');
        setTimeout(function () {
            $('#dw-modal .copy').html('');
        }, 400); //Trick otherwise you will see empty box first then it disappears
    }

    function getProductContent(pid) {
        var content = '';
        if (productList[pid]) {
            content = '<div class="d-flex col2">';
            if (productList[pid]['gallery']) {
                content += '<div class="col rel"><div class="carousel padded"><div class="slide"><img src="';
                content += productList[pid]['gallery'].join('" alt="" /></div><div class="slide"><img src="');
                content += '" alt="" /></div></div><div id="inner-nav"></div></div>';
            }
            content += '<div class="col"><h2 class="hpink title">Donut Worries<sup>&trade;</sup> ' + productList[pid]['title'] + '</h2>';
            content += '<div class="desc">' + productList[pid]['desc'] + '</div>';
            if (productList[pid]['stores']) {
                content += '<h2 class="hpink">Where to Buy</h2><ul class="store-list plain d-flex ai-center jc-start">';
                for (i = 0; i < productList[pid]['stores'].length; i++) {
                    if (productList[pid]['stores'][i].link == '') {
                        content += '<li>Coming Soon</li>';
                    } else {
                        content += '<li><a href="' + productList[pid]['stores'][i].link + '" target="_blank"><img src="' + productList[pid]['stores'][i].thumb + '" alt="" /></a></li>';
                    }
                }
                content += '</ul>';
            }
            content += '</div></div>';
        }
        return content;
    }


    if ($('.rising-img').length > 0) {
        generateFloatingImage($('.rising-img.main'), 30);
    }

    $('.show-info').on('click', function (e) {
        e.preventDefault();
        $('body').addClass('ov-hidden');
        $('#dw-modal .copy').html(getProductContent($(this).parents('.product-item').data('prod-id')));
        $('#dw-modal .carousel').slick({
            infinite: false,
            rows: 0,
            prevArrow: '<button type="button" class="prev" title="Previous">&nbsp;</button>',
            nextArrow: '<button type="button" class="next slider-arrow-green" title="Next">&nbsp;</button>',
            accessibility: true,
            slidesToShow: 1,
            appendDots: '#inner-nav',
            dots: true,
            adaptiveHeight: false,
        });
        $("#dw-modal .copy").mCustomScrollbar({ scrollbarPosition: 'outside' });
        $('#dw-modal').addClass('active');
        setTimeout(function () {
            $('#dw-modal .carousel').slick('refresh');
        }, 200);
    });

    if ($('.carousel').length > 0) {
        $('.carousel').each(function () {
            $('.carousel').slick({
                infinite: false,
                //slidesToShow: 1,
                rows: 0,
                prevArrow: '<button type="button" class="prev" title="Previous">&nbsp;</button>',
                nextArrow: '<button type="button" class="next slider-arrow-green" title="Next">&nbsp;</button>',
                //nextArrow: '',
                accessibility: true,
                slidesToShow: 4,
                //autoplaySpeed: speed,
                adaptiveHeight: true,

                responsive: [
                    {
                        breakpoint: 970,
                        settings: {
                            slidesToShow: 3,
                        },
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                        },
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1,
                        },
                    },
                ],
            });
        });
    }

})(jQuery);